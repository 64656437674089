<template>
  <div class="settings">
    <div class="container-fluid">
      <h1>Settings</h1>
      <grid>
        <grid-col size="5" v-if="userCan('view', 'tag')">
          <card title="Tags" v-if="userCan('edit', 'tag')">
            <p>
              This is the list of tags that are available to the user when editing or approving a
              device. Only these tags are valid and removed tags will also be removed for the
              devices that have these tags. Please note that tags will be removed after you click
              "Save tags". Every tag that you delete is checked to see how many devices are using
              it.
            </p>
            <vue-tags-input
              class="tags-input"
              v-model="tag"
              :tags="tags"
              :autocomplete-min-length="0"
              :autocomplete-items="filteredTags"
              @tags-changed="(newTags) => (tags = newTags)"
              @before-deleting-tag="beforeDeleteTag"
              @before-adding-tag="beforeAddTag"
            />
            <div class="property">
              <div v-if="tagsToDelete.length">
                Tags to remove: <span v-html="tagsToDelete.join(', ')"></span> [<a
                  @click="undoDeletedTags"
                  class="link"
                  >undo</a
                >]
              </div>
              <base-button title="Save Tags" v-on:click="saveTags" type="THEMED" iconType="SAVE" />
            </div>
          </card>
          <card title="Tags" v-else>
            <p>
              This is the list of tags that are available to the user when editing or approving a
              device. Only these tags are valid.
            </p>
            <span
              v-html="
                tags
                  .map((t) => {
                    return '<span class=&quot;ti-tag view-only-tag&quot;>' + t.text + '</span>';
                  })
                  .join('')
              "
            />
          </card>
          <card title="Roletags" v-if="userCan('edit', 'tag')">
            <p>
              This is the list of role tags that are available to the user when editing or approving
              a device. These tags correspond to scripts tags and determine which scripts will be
              assigned to a a device on approval.
            </p>
            <vue-tags-input
              class="tags-input"
              v-model="roletag"
              :tags="roletags"
              :autocomplete-min-length="0"
              :autocomplete-items="filteredRoleTags"
              @tags-changed="(newRoleTags) => (roletags = newRoleTags)"
              @before-deleting-tag="beforeDeleteRoleTag"
              @before-adding-tag="beforeAddRoleTag"
            />
            <div class="property">
              <div v-if="roleTagsToDelete.length">
                Tags to remove: <span v-html="roleTagsToDelete.join(', ')"></span> [<a
                  @click="undoDeletedRoleTags"
                  class="link"
                  >undo</a
                >]
              </div>
              <base-button
                title="Save Roletags"
                v-on:click="saveRoleTags"
                type="THEMED"
                iconType="SAVE"
              />
            </div>
          </card>
          <card v-if="editSSLTag" title="SSL Certificate Tag">
            <p>
              Select a tag to be attached to devices that allows SSL certificates to be deployed to
              this device
            </p>

            <select v-model="ssltag" name="ssltag" class="input-select" v-if="tags">
              <option :value="null">Select a tag</option>
              <option v-for="tag in tags" :key="tag.text" :value="tag.text">
                {{ tag.text }}
              </option>
            </select>

            <div class="property" v-if="tags && ssltag">
              <base-button
                title="Save SSL tag"
                v-on:click="saveSSLTag"
                type="THEMED"
                iconType="SAVE"
              />
            </div>
          </card>
          <install-script-display :organizationId="organizationId" />
        </grid-col>
        <grid-col size="7" v-if="userCan('view', 'script')">
          <card title="Standard Scripts">
            <div class="property">
              <p>
                All newly approved devices will automatically get the selected scripts to run. This
                will make sure that all devices have the same setup.
              </p>
            </div>
            <div>
              <StandardScripts />
            </div>
          </card>
        </grid-col>
      </grid>
    </div>
  </div>
</template>
<script>
import VueTagsInput from '@johmun/vue-tags-input';
import Utils from '@/utils';
import Card from '../components/Card/Card.vue';
import StandardScripts from '../components/Table/StandardScripts.vue';
import InstallScriptDisplay from '../components/InstallScript/InstallScriptDisplay.vue';
import GridCol from '../components/Grid/GridCol.vue';
import Grid from '../components/Grid/Grid.vue';
import BaseButton from '../components/BaseButton/BaseButton.vue';

export default {
  name: 'settings',
  components: {
    Card,
    StandardScripts,
    VueTagsInput,
    InstallScriptDisplay,
    Grid,
    GridCol,
    BaseButton,
  },
  data() {
    return {
      organization: null,
      tag: '',
      tags: [],
      roletag: '',
      roletags: [],
      autocompleteTags: [],
      autocompleteRoletags: [],
      tagsToDelete: [],
      roleTagsToDelete: [],
      ssltag: null,
      editSSLTag: false,
    };
  },
  computed: {
    organizationId() {
      if (this.organization?._id) {
        return this.organization._id;
      }
      const user = Utils.getUser();
      return user.organization;
    },
    filteredTags() {
      return this.autocompleteTags.filter(
        (i) => i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1,
      );
    },
    filteredRoleTags() {
      return this.autocompleteRoletags.filter(
        (i) => i.text.toLowerCase().indexOf(this.roletag.toLowerCase()) !== -1,
      );
    },
  },
  methods: {
    async beforeDeleteTag(obj) {
      const response = await this.$utils.http().get(`/api/v1/tags/${obj.tag.text}/devices`);

      if (response && response.data && response.data.devices) {
        this.tagsToDelete.push(
          `<span class="warning">${obj.tag.text} (${response.data.devices})</span>`,
        );
      } else {
        this.tagsToDelete.push(`<span class="success">${obj.tag.text}</span>`);
      }

      obj.deleteTag();
    },
    async beforeDeleteRoleTag(obj) {
      const response = await this.$utils.http().get(`/api/v1/roletags/${obj.tag.text}/devices`);

      if (response && response.data && response.data.devices) {
        this.tagsToDelete.push(
          `<span class="warning">${obj.tag.text} (${response.data.devices})</span>`,
        );
      } else {
        this.roleTagsToDelete.push(`<span class="success">${obj.tag.text}</span>`);
      }

      obj.deleteTag();
    },
    async beforeAddTag(obj) {
      obj.addTag();
    },
    async beforeAddRoleTag(obj) {
      // eslint-disable-next-line no-param-reassign
      obj.tag.classes = 'role';
      obj.addTag();
    },
    async undoDeletedTags() {
      this.getTags();
    },
    async undoDeletedRoleTags() {
      this.getRoleTags();
    },
    async getTags() {
      const response = await this.$utils.http().get('/api/v1/tags');
      const mappedTags = response.data.sort().map((e) => ({
        text: e,
      }));

      this.tags = mappedTags;
      this.autocompleteTags = mappedTags;
      this.tagsToDelete = [];
    },
    async getRoleTags() {
      const response = await this.$utils.http().get('/api/v1/roletags');
      const mappedTags = response.data.sort().map((e) => ({
        text: e,
        classes: 'role',
      }));

      this.roletags = mappedTags;
      this.autocompleteRoletags = mappedTags;
      this.roleTagsToDelete = [];
    },
    userCan(...args) {
      return Utils.userCan(...args);
    },
    async saveTags() {
      try {
        const response = await this.$utils.http().post('/api/v1/tags', {
          tags: this.tags.map((e) => e.text),
        });

        if (response.data.devicesModified) {
          this.$noty.success(
            `The tags were saved! ${response.data.devicesModified} device(s) were modified.`,
            { timeout: 2500 },
          );
        } else {
          this.$noty.success('The tags were saved', { timeout: 1500 });
        }
        this.getTags();
      } catch (e) {
        this.$noty.error(`Something went wrong ${e.response.data.message}`);
      }
    },
    async saveRoleTags() {
      try {
        const response = await this.$utils.http().post('/api/v1/roletags', {
          tags: this.roletags.map((e) => e.text),
        });

        if (response.data.devicesModified) {
          this.$noty.success(
            `The roletags were saved! ${response.data.devicesModified} device(s) were modified.`,
            { timeout: 2500 },
          );
        } else {
          this.$noty.success('The roletags were saved', { timeout: 1500 });
        }
        this.getRoleTags();
      } catch (e) {
        this.$noty.error(`Something went wrong ${e.response.data.message}`);
      }
    },
    isAdmin() {
      return Utils.isAdmin();
    },
    async getOrganization() {
      this.organization = await Utils.getOrganization();
      this.ssltag = this.organization?.sslCertificateTag;
    },
    async saveSSLTag() {
      const url = '/api/v1/organizations/ssltag';
      const response = await Utils.post(url, { tag: this.ssltag }, this);
      if (response.result) {
        this.$noty.success('The tag was saved', { timeout: 1500 });
      } else {
        this.$noty.error('The tag could not be saved');
      }
    },
  },
  mounted() {
    this.getTags();
    this.getRoleTags();
    this.getOrganization();
  },
};
</script>

<style lang="scss">
.view-only-tag {
  font-size: 12px;
  display: inline-block;
}
.vue-tags-input {
  /* override max width */
  max-width: 100% !important;
}
</style>
