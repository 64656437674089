import { render, staticRenderFns } from "./InstallScriptDisplay.vue?vue&type=template&id=fcefa636&scoped=true&"
import script from "./InstallScriptDisplay.vue?vue&type=script&lang=js&"
export * from "./InstallScriptDisplay.vue?vue&type=script&lang=js&"
import style0 from "./InstallScriptDisplay.vue?vue&type=style&index=0&id=fcefa636&prod&scoped=true&lang=scss&"
import style1 from "./InstallScriptDisplay.vue?vue&type=style&index=1&id=fcefa636&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.0_68125b3067bc9264b7f5e63a32c23d52/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fcefa636",
  null
  
)

export default component.exports