import { render, staticRenderFns } from "./StandardScripts.vue?vue&type=template&id=661e8918&scoped=true&"
import script from "./StandardScripts.vue?vue&type=script&lang=js&"
export * from "./StandardScripts.vue?vue&type=script&lang=js&"
import style0 from "./StandardScripts.vue?vue&type=style&index=0&id=661e8918&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.0_68125b3067bc9264b7f5e63a32c23d52/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "661e8918",
  null
  
)

export default component.exports