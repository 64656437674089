<template>
  <div class="command-table">
    <TableFilter
      :source="standardScripts"
      :fields="[{ key: 'description', label: 'Script', type: 'RegExp' }]"
      @filtered="
        (filtered) => {
          standardScriptsDisplay = filtered;
        }
      "
    />
    <table-component
      v-if="standardScripts"
      v-on:rowClick="handleRowClick"
      columnName="columns-StandardScripts"
      :columns="tableColumns"
      :table-data="standardScriptsDisplay"
      :rowsClickable="true"
      :showFalseBooleans="true"
    />
  </div>
</template>

<script>
import Utils from '@/utils';
import TableFilter from './Filter.vue';
import TableComponent from './TableComponent.vue';

export default {
  name: 'standardScripts',
  components: { TableFilter, TableComponent },
  data() {
    return {
      standardScriptsMulti: {
        active: [],
        inactive: [],
        showActions: false,
      },
      standardScripts: [],
      standardScriptsDisplay: [],
      tableColumns: [
        {
          title: 'First boot',
          key: 'firstBoot',
          sort: true,
        },
        {
          title: 'OS',
          key: 'os',
          sort: true,
        },
        {
          title: 'Sort',
          key: 'sort',
          sort: true,
        },
        {
          title: 'Script',
          key: 'description',
          sort: true,
        },
        {
          title: 'Tags',
          key: 'tags',
          display: (val) => (val ? val.join(' | ') : ''),
          sort: true,
        },
        {
          title: 'Reboot',
          key: 'reboot',
          sort: true,
        },
      ],
    };
  },
  methods: {
    userCan(...args) {
      return Utils.userCan(...args);
    },
    async getstandardScripts() {
      const response = await this.$utils.http().get('/api/v1/standard-scripts');
      // reset, so we can reload them when changed
      this.standardScripts = [];
      response.data.active.forEach((script) => {
        this.standardScripts.push({
          id: script._id,
          firstBoot: true,
          ...script.command,
          action: 'disable',
        });
      });
      response.data.inactive.forEach((script) => {
        this.standardScripts.push({
          id: script._id,
          firstBoot: false,
          ...script,
          action: 'enable',
        });
      });
    },

    async enableStandardScript(scriptId) {
      await this.$utils.http().post('/api/v1/standard-scripts', {
        script: scriptId,
      });
      this.getstandardScripts();
    },

    async disableStandardScript(scriptId) {
      await this.$utils.http().delete(`/api/v1/standard-scripts/${scriptId}`);
      this.getstandardScripts();
    },

    async handleRowClick(id, entry) {
      if (entry.firstBoot) {
        this.disableStandardScript(id);
      } else {
        this.enableStandardScript(id);
      }
    },
  },
  mounted() {
    this.getstandardScripts();
    if (this.userCan('edit', 'script')) {
      // this.standardScriptsMulti.showActions = true;
      this.tableColumns.push({
        title: 'Action',
        key: 'action',
      });
    }
  },
};
</script>

<style scoped lang="scss">
.command-table {
  overflow-x: auto;
  padding-bottom: 30px;
}

.command-table table {
  width: 100%;
  border-collapse: collapse;

  .loader {
    text-align: center;
    padding: 20px 0;

    img {
      height: 30px;
    }
  }

  tr {
    height: 30px;
  }

  tbody tr {
    cursor: pointer;

    &.selected {
      background: var(--table-row-hover-color);
    }

    &:hover {
      background: var(--table-row-hover-color);
    }
  }

  th,
  td {
    padding: 0 10px;
  }

  thead tr th {
    //border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    text-align: left;
    background: rgba(255, 255, 255, 0.04);
  }

  th {
    font-weight: normal;
  }
}
</style>
